import React from 'react'
import { Card, Row, Col, Container } from 'react-bootstrap'
import { Escalable, Movil, Nube, Web } from '../../../constants/images'

import './services.scss'

export default function services() {
    const services = [
        {
            titulo: 'Aplicaciones Móviles',
            texto: 'Ya sea híbrida o nativa. Creamos la app basada en tus necesidades.',
            icono: Movil
        },
        {
            titulo: 'Sistemas WEB',
            texto: 'Haciendo uso de las tecnologías actuales, seguro, adaptable y sobre todo ¡Funcional!',
            icono: Web
        },
        {
            titulo: 'Cómputo en la Nube',
            texto: 'Tu sistema disponible sin depender de una instalación, haciendo uso de nuestro servidores en la nube.',
            icono: Nube
        },
        {
            titulo: 'Sistemas Escalables',
            texto: 'Tu sistema se construye con la visión si tu negocio, el sistema lo hace contigo.',
            icono: Escalable
        }
    ]
    return (
        <Container className="services">
            <div className="text-center my-5">
                <h2 className="services__title font-weight-light">
                    Tu idea transformada en negocio
                    <br/>
                    <small className="services__subtitle">
                        Mira lo que podemos hacer por ti.
                    </small>
                </h2>
            </div>
            <Row>
                {
                    services.map(({titulo, texto, icono}, idx) =>
                        <Col key={idx} md={6} className="mt-5">
                            <Card className="services__card">
                                <Card.Body>
                                    <Card.Title className="services__card-title text-center">
                                        { titulo }
                                    </Card.Title>
                                    <Card.Text className="text-center services__card-text">
                                            <img className="services__card-image" src={icono} alt={'icono ' + titulo }/>
                                            <br/>
                                            { texto }
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                }
            </Row>
        </Container>
    )
}
