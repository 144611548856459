import React from 'react'
import './hero.scss'

// Components
import { Button } from 'react-bootstrap'

const Hero = () => {

    const contacto = () => {
        const ele = document.querySelector('.contacto')
        
        const offset = ele.offsetTop

        window.scrollTo(0, offset)
    }


    return (
        <>
            <div className="hero">
                <div className="hero__container">
                    <div className="hero__main">
                        <div className="hero__content">
                            <div className="hero__title">
                                Desarrollamos tus ideas para convertirlas en la mejor herramienta 
                            </div>
                            <div className="hero__subtitle">
                                5 años de experiencia desarrollando soluciones al alcance de las empresas desde Micro hasta Macro. Utilizando herramientas y técnicas de desarrollo actual.
                            </div>
                            <Button onClick={ () => contacto() } className="hero__button"  size="lg" variant="info">
                                <span className="text-uppercase">
                                    Solicitar consulta
                                </span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero