import React from 'react'
import { Form } from "react-bootstrap";

const Input = ({label, type, tag, helpText, change, name, rules, value}) => {
    const onChange = (event) => {
        const { value, name } = event.target
        change({
            value,
            name
        })
    }

    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control value={value} name={name} onChange={ onChange } type={type} as={tag} {  ...rules   } ></Form.Control>
                <Form.Text>{ helpText }</Form.Text>
            </Form.Group>
        </div>
    )
}

export default Input