import React, { useState } from 'react'
import { faEnvelope, faMapMarkerAlt, faMobile } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import Input from '../../form/input'
// Services
import { ContactoService } from '../services/contacto-service';


import './contacto.scss'
import Swal from 'sweetalert2'

const defaultForm = [
    {
        name: 'nombre',
        label: 'Nombre completo',
        col: 12,
        value: '',
        rules: {
            required: true
        }
    },
    {
        name: 'telefono',
        label: 'Número de contacto',
        col: 6,
        value: '',
        type: 'tel',
        helpText: 'Ingrese los 10 dígitos por favor.',
        rules: {
            required: true,
            minLength: 10
        }
    },
    {
        name: 'correo',
        label: 'Correo electrónico',
        col: 6,
        value: '',
        type: 'email',
        rules: {
            required: true
        }
    },
    {
        name: 'asunto',
        label: 'Asunto',
        col: 12,
        value: '',
        rules: {
            required: true
        }
    },
    {
        name: 'mensaje',
        label: 'Mensaje',
        col: 12,
        value: '',
        tag: 'textarea',
        rules: {
            required: true
        }
    },
]

const Contacto = () => {

    const [form, setForm] = useState([...defaultForm])

    const info = [
        {
            icon: faMapMarkerAlt,
            texto: 'Hermosillo, Sonora, México' 
        },
        {
            icon: faEnvelope,
            texto: 'info@mactenova.mx'
        },
        {
            icon: faMobile,
            texto: '+52 (662) 688 5504'
        },
        {
            texto: 'Lunes – Sábado: 8:00 am - 4:00 pm'
        }
    ]
    
    const change = (input) => {
        const { name, value } = input
        
        const newForm = form.map(item => {
            if (item.name === name ) {
                item.value = value
            }

            return item
        })

        setForm([
            ...newForm
        ])
    }

    const sendInfo = async (event) => {
        event.preventDefault()
        
        const data = {}

        const formFields = [...form]
        
        console.log(formFields)

        formFields.forEach(field => {
            const { name, value } = field

            data[name] = value
        })

        Swal.fire('Enviando solicitud')
        Swal.showLoading()

        try {
            const { data: response } = await ContactoService.send(data)

            console.log(response)

            setForm([
                ...defaultForm
            ])

            Swal.fire('Exito', 'Se ha enviado el correo de contacto exitosamente', 'success')
            
        } catch(err) {
        
            Swal.fire('Error', 'Hubo un problema al enviar el correo de contacto, por favor intente más tarde', 'error')

        }

    }

    const renderForm = form.map(( input, idx)  => {
        const { label, col, value, tag, helpText, name, rules, type } = input

        return (
            <Col key={idx} md={ col } >
                <Input value={value} name={name} label={label} tag={tag} value={value} helpText={helpText} change={change}  rules={rules} type={type} />
            </Col>
        )
    })

    const renderInfo = info.map(({icon, texto}, idx) => 
        <Row className="mt-4" key={idx}>
            {
                icon &&
                <Col xs={1}>
                    { icon && <FontAwesomeIcon icon={ icon } />}
                </Col>
            }
            <Col>
                { texto }
            </Col>
        </Row>
    )
    
    return (
        <div className="mt-5 contacto">
            <div className="contacto__container">
                <div className="contacto__main">
                    <Container>
                        <h2 className="contacto__title font-weight-bolder">Contáctanos</h2>
                        <p className="mb-5 contacto__subtitle">
                            <i>
                                Pon tu proyecto en manos de los mejores ingenieros, juntos haremos la app que tu negocio necesita. ¡Cotiza con nosotros! 
                            </i>
                        </p>
                        <Row>
                            <Col md="6">
                                <Form onSubmit={sendInfo} className="contacto__form">
                                    <Row>
                                        { renderForm }
                                    </Row>
                                    <Form.Group>
                                        <Button type="submit" variant="primary" size="lg" block>
                                            Enviar solicitud
                                        </Button>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col md={ { order: 4, offset:2 }  }>
                                <div className="contacto__info">
                                    <h3 className="font-weight-normal">Información de contacto</h3>
                                    <div>
                                        { renderInfo }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default Contacto