import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
// Nosotros
import { Android,
    Angular,
    Apple,
    ElectronJs,
    Firebase,
    Ionic,
    Mysql,
    Node,
    PHP,
    Postgres,
    GoogleCloud,
    ReactIcon,
    Heroku, Flutter
} from '../../../constants/images'


import './nosotros.scss'

const Nosotros = () => {
    

    const tecnologias = [
        {
            icon: Angular,
            alt: 'Angular'
        },
        {
            icon: ReactIcon,
            alt: 'React Js'
        },
        {
            icon: Android,
            alt: 'Android'
        },
        {
            icon: Apple,
            alt: 'Apple'
        },
        {
            icon: Node,
            alt: 'Node'
        },
        {
            icon: PHP,
            alt: 'PHP'
        },
        {
            icon: ElectronJs,
            alt: 'Electron JS'
        },
        {
            icon: Firebase,
            alt: 'firebase'
        },
        {
            icon: Ionic,
            alt: 'ionic'
        },
        {
            icon: Flutter,
            alt: 'Flutter',   
        },
        {
            icon: Mysql,
            alt: 'mysql'
        },
        {
            icon: Postgres,
            alt: 'PostgreSQL'
        },
        {
            icon: GoogleCloud,
            alt: 'Google Cloud',
            md: {
                span: 1,
                offset: 5
            }
        },
        {
            icon: Heroku,
            alt: 'Heroku',
        },
    ]

    return (
        <div className="nosotros">
            <Container className="">
                <div className="nosotros__content">
                    <h2 className="nosotros__title">Innovación, nuestra base</h2>
                    <p className="nosotros__text">
                        5 años de experiencia desarrollando soluciones al alcance de las empresas desde Micro hasta Macro. Utilizando herramientas y técnicas de desarrollo actual.
                    </p>
                    <div className="mt-5">
                        <Row>
                            {
                                tecnologias.map(({ icon, alt, md, lg, xs }, idx) => 
                                    <Col xs={ xs ? xs : 3 } lg={ lg ? lg : 1 }  md={ md ? md : 1 } key={idx}>
                                        <img src={icon} alt={alt} />
                                    </Col>
                                )
                            }
                        </Row>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Nosotros