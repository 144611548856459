import React from "react"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/home/hero/hero"
import Services from '../components/home/services/services'
import Contacto from '../components/home/contacto/contacto'
import Nosotros from "../components/home/nosotros/nosotros"

const IndexPage = () => (
  <Layout>
    <SEO title="Principal" description="Empresa dedicada al desarrollo de software en apoyo a las micro empresas." og />
    <Hero/>
    <Nosotros/>
    <Services/>
    <Contacto/>
  </Layout>
)

export default IndexPage
